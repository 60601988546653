import React from "react"
import "./lyrique-app.css"
import Footer from "../../components/footer"
import WorkHeader from "../../components/workHeader"
import lyriqueApp from "../../../static/work_headers/lyrique-app.png"
import Dict from "../../langs/dict"
import WorkAbout from "../../components/workAbout"
import Perk from "../../components/perks"
import lyrapp1 from "../../../static/works_perks/lyrapp_1.png"
import lyrapp3 from "../../../static/works_perks/lyrapp_2.png"
import lyrapp2 from "../../../static/works_perks/lyrapp_3.png"
import Contact from "../../components/contact"
import logo from "../../../static/logos/lyrique_logo.png"
import Layout from "../../components/layoutWorks"

export default () => {
  const lang = "en"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Web Application - Lyrique"
      seoDescription="Based on the initial requirements, webpanda created a secure internal application to track, search for and archive key investment data."
    >
      <div id="works-lyriqueapp-background">
        <WorkHeader
          title={dict.getTrans("work_lyrapp_title")}
          subTitle={dict.getTrans("work_lyrapp_subtitle")}
          pic={lyriqueApp}
          navLang={lang}
          hl="#306CA9"
          pageUrl="/works/lyrique-app"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("lyrapp_about_title")}
        p1={dict.getTrans("lyrapp_about_p1")}
        p2={dict.getTrans("lyrapp_about_p2")}
        logo={logo}
      />
      <div id="works-lyrapp-perks-holder">
        <Perk
          title={dict.getTrans("lyrapp_perk1_title")}
          image={lyrapp1}
          text={dict.getTrans("lyrapp_perk1_text")}
        />
        <Perk
          title={dict.getTrans("lyrapp_perk2_title")}
          image={lyrapp2}
          text={dict.getTrans("lyrapp_perk2_text")}
        />
        <Perk
          title={dict.getTrans("lyrapp_perk3_title")}
          image={lyrapp3}
          text={dict.getTrans("lyrapp_perk3_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
